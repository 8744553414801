export const tabs = {
  variants: {
    line: {
      tab: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
