export const colors = {
  brand: {
    50: "#E6EEFE",
    100: "#BACFFD",
    200: "#8DB0FC",
    300: "#6191FA",
    400: "#3472F9",
    500: "#0753F8",
    600: "#0643C6",
    700: "#043295",
    800: "#032163",
    900: "#011132",
  },
};
